import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import "./App.css";
import Header from "./components/Header/Header";
import Home from "./Pages/Home";
import About from "./Pages/About/About";
import Resume from "./Pages/Resume/Resume";
import Service from "./Pages/OurService/Service";
import Portfolio from "./Pages/Ourportfolio/Portfolio";
import Blog from "./Pages/Blog";
import Footer from "./components/Footer/Footer";
import WhatsAppPopup from "./Pages/Whatsapp";
import Contact from "./Pages/Contact/Contact";
import OpenForm from "./Pages/FormOpen";
import Term from "./Pages/Term-and-condition";
import Privacy from "./Pages/Privacy";
import ScrollProgress from "./Pages/Scrolltop";
import TawkToChat from "./components/TawkToChat";
import NotFound from "./Pages/NotFound";
import ServicePage from "./Pages/ServicePage";


function App() {
  const location = useLocation();

  useEffect(() => {
    // Google Analytics
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname + location.search);

    // Google Tag Manager
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        page: location.pathname,
      }
    });
  }, [location]);

  return (
    <div>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about-us.php" element={<About />} />
        <Route exact path="/resume.php" element={<Resume />} />
        <Route exact path="/services.php" element={<Service />} />
        <Route exact path="/portfolio.php" element={<Portfolio />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/contact.php" element={<Contact />} />
        <Route exact path="/terms-and-conditions.php" element={<Term />} />
        <Route exact path="/privacy-policy.php" element={<Privacy />} />
        <Route path=":slug" element={<ServicePage />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/not-found" />} />
      </Routes>
      <Footer />

      <WhatsAppPopup />
      <OpenForm />
      <ScrollProgress />
      <TawkToChat />
    </div>
  );
}

export default App;
