import { useParams, Navigate, Link } from 'react-router-dom'; // Add Navigate for redirection
import { useEffect, useState } from 'react';
import Meta from './Meta';


function ServicePage() {
    const { slug } = useParams();
    const [service, setService] = useState(null);
    const [isNotFound, setIsNotFound] = useState(false); // State to handle 404

    const [servicesList, setServicesList] = useState([]);
 
    useEffect(() => {
        fetch(`https://www.fahimshakir.com/blog/wp-json/custom/v1/services/slug/${slug}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Service not found');
                }
                return response.json();
            })
            .then(data => {
                if (data && data.title) {
                    setService(data);
                    setIsNotFound(false);
                } else {
                    setIsNotFound(true);
                }
            })
            .catch(error => {
                console.error('Error fetching service:', error);
                setIsNotFound(true)
            });

        fetch(`https://www.fahimshakir.com/blog/wp-json/custom/v1/services`)
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data) && data.length > 0) {
                    setServicesList(data); // Set the fetched data
                } else {
                    setServicesList([]); // Set an empty array if no data
                }
            })
            .catch(error => console.error('Error fetching services:', error));

    }, [slug]);

    if (isNotFound) {
        return <Navigate to="/not-found" />;
    }

    if (!service) {
        return <div>.</div>;
    }

    return (
        <>
            <Meta
                title={service.yoast_seo_title}
                description={service.yoast_seo_description}
                keywords={service.yoast_focus_keywords}
            />

            <div className="service-image"><h1>{service.title}</h1></div>
            <div className="conatainer service-page">
                <div className='row'>
                    <div className='col-4 left-sidebar'>
                        <h3>OUR SERVICES</h3>
                        <div className='left-sidebar-container'>
                            <ul className="nav-link">
                                {
                                    servicesList.map((data, index) => (
                                        ('/' + slug == data.url) ?
                                            <li key={index} className='item-avtive'>
                                                <span>
                                                    {data.title}
                                                </span>
                                            </li>
                                            :
                                            <li key={index}>
                                                <Link to={data.url}>
                                                    {data.title}
                                                </Link>
                                            </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='box-container'>
                            <h2 className='title'>{service.title}</h2>
                            <img className='featured-image' src={service.featured_image} alt="project" loading="lazy" width={100} />
                            <div dangerouslySetInnerHTML={{ __html: service.content }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='divider'></div>
        </>
    );
}

export default ServicePage;
