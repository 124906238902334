import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

// Initialize Google Analytics
ReactGA.initialize('UA-111937358-1'); // Replace with your Google Analytics tracking ID

// Initialize Google Tag Manager
const tagManagerArgs = {
  gtmId: 'GTM-M957JSJF' // Replace with your Google Tag Manager ID
};

TagManager.initialize(tagManagerArgs);

// Track page views
const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname + window.location.search);
};

// Setup listener for route changes
const handleRouteChange = () => {
  logPageView();
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App onRouteChange={handleRouteChange} />
  </BrowserRouter>
);

reportWebVitals();
