import React, { useState, useEffect } from "react";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import "../Ourportfolio/All.css";
import SkeletonLoader from "./SkeletonLoader"; // Adjust the path as needed
 
const Allmain = ({ images, title, link, category,activeTab }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setContentLoaded(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const handleLinkClick = (url, e) => {
    e.preventDefault();
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleViewLargerClick = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  return (
    <div className={`over ${(category === activeTab || activeTab == 'all')? 'd-block' : 'd-none'}`}>
      <div className="All">
        {!contentLoaded && <SkeletonLoader width="100%" height="300px" />}
        {contentLoaded && (
          <img
            src={images}
            alt= {title}
            style={{ display: "block", cursor: "default" }}
            loading="lazy"
          />
        )}
        <div className="maininfo">
          <a
            href={link}
            className="orinfo"
            onClick={(e) => handleLinkClick(link, e)} >
            More info
          </a>
          <a
            href="/"
            className="orinfo"
            onClick={handleViewLargerClick}
          >
            View Larger
          </a>
        </div>
      </div>
      {!contentLoaded ? (
        <SkeletonLoader width="100%" height="20px" />
      ) : (
        <p className="newhouse">{title}</p>
      )}

      {isOpen && (
        <Lightbox
          mainSrc={images}
          nextSrc={images}
          prevSrc={images}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(images)
          }
          onMoveNextRequest={() =>
            setPhotoIndex(images)
          }
        />
      )}
    </div>
  );
};

export default Allmain;

