import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

const Services = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    fetch(`https://www.fahimshakir.com/blog/wp-json/custom/v1/services`)
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data) && data.length > 0) {
          setServices(data); // Set the fetched data
        } else {
          setServices([]); // Set an empty array if no data
        }
      })
      .catch(error => console.error('Error fetching services:', error));
  }, []);

  // If the services array is empty or still loading
  if (services.length === 0) {
    return <div>Loading...</div>;
  }

  console.log(services); // You can check the data being logged here

  return (
    <>
      <div className="service-bg">
        <div className="col-md-12">
          <h4 className="text-center sec-review-ti">Project Undertaken</h4>
          <p className="des-choose">
            Crafting innovative web experiences with cutting-edge technology to elevate digital presence and user engagement.
          </p>
        </div>

        <div className="containerve">
          <ul className="Newflax">
            {services.map((service, index) => (
              <li key={index} > 
                <Link to={service.url}>{service.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Services;
