import React from "react";

import Balance1 from "../image/project-1.webp";
import Balance2 from "../image/scope-1.webp";
import Balance3 from "../image/architecture.webp";
import Balance4 from "../image/design.webp";
import Balance5 from "../image/development.webp";
import Balance6 from "../image/testing.webp";
import Balance7 from "../image/launch.webp";
import Balance8 from "../image/maintainance.webp";
import Balance9 from "../image/web.webp";
import animated from "../image/web-design-process.webp";

const BalanceTime = () => {
    return (
        <>
            <div className="balance_time_header">
                <div className="container">
                    <div className="balance_time_title">
                        <h2>Balance Your Time And Money With Our Web Design and Development Process</h2>
                    </div>
                    <div className="moving_image">
                        <img decoding="async" src={animated} alt="design-process" width="400px" height="250px" />
                    </div>
                    <div className="balance_time_items">
                        <div className="balance_time_item">
                            <div className="item_cloums">
                                <div className="image_loaded">
                                <img
                                    src={Balance1}
                                    alt="project"
                                    loading="lazy"
                                    width="100px"
                                    height="100%"
                                />
                                </div>
                            </div>
                            <div className="content">
                                <h3>1. Project Definition</h3>
                                <p>We start with defining the business goals which website should accomplish.</p>
                            </div>
                        </div>
                        <div className="balance_time_item">
                            <div className="item_cloums">
                                <div className="image_loaded">
                                <img
                                    src={Balance2}
                                    alt="project"
                                    loading="lazy"
                                    width="100px"
                                    height="100%"
                                />
                                </div>
                            </div>
                            <div className="content">
                                <h3>2. Project Scope</h3>
                                <p>Our Expert web designers work together with you to write the scope of the project.</p>
                            </div>
                        </div>
                    </div>
                    <div className="balance_time_items">
                        <div className="balance_time_item">
                            <div className="item_cloums">
                                <div className="image_loaded">
                                    <img
                                        src={Balance1}
                                        alt="scope"
                                        loading="lazy"
                                        width="80px"
                                        height="90px"
                                    />
                                </div>
                            </div>
                            <div className="content">
                                <h3>3. Website Architecture</h3>
                                <p>The architecture of the website determines how the users will interact with the website.</p>
                            </div>
                        </div>
                        <div className="balance_time_item">
                            <div className="item_cloums">
                                <div className="image_loaded">
                                    <img
                                        src={Balance3}
                                        alt="design"
                                        loading="lazy"
                                        width="75px"
                                        height="75px"
                                    />
                                </div>
                            </div>
                            <div className="content">
                                <h3>4. Website Designing</h3>
                                <p>Our creative web designers in India then convert the architecture into visually appealing web design.</p>
                            </div>
                        </div>
                    </div>
                    <div className="balance_time_items balance_time_items_botton">
                        <div className="balance_time_item">
                            <div className="item_cloums">
                                <div className="image_loaded">
                                    <img
                                        src={Balance4}
                                        alt="development"
                                        loading="lazy"
                                        width="100px"
                                        height="100%"
                                    />
                                </div>
                            </div>
                            <div className="content">
                                <h3>5. Web Development</h3>
                                <p>Our web development team then convert these designs into fully functional web pages.</p>
                            </div>
                        </div>
                        <div className="balance_time_item">
                            <div className="item_cloums">
                                <div className="image_loaded">
                                    <img
                                        src={Balance5}
                                        alt="testing"
                                        loading="lazy"
                                        width="100px"
                                        height="100%"
                                    />
                                </div>
                            </div>
                            <div className="content">
                                <h3>6. Website Testing</h3>
                                <p>Our QA team tests the website in real online environment across all devices.</p>
                            </div>
                        </div>
                        <div className="balance_time_item">
                            <div className="item_cloums">
                                <div className="image_loaded">
                                    <img
                                        src={Balance6}
                                        alt="launch"
                                        loading="lazy"
                                        width="100px"
                                        height="100%"
                                    />
                                </div>
                            </div>
                            <div className="content">
                                <h3>7. Website Launch</h3>
                                <p>After launch we monitor the website for next two months free of cost to check the performance.</p>
                            </div>
                        </div>
                        <div className="balance_time_item">
                            <div className="item_cloums">
                                <div className="image_loaded">
                                    <img
                                        src={Balance6}
                                        alt="maintainance"
                                        loading="lazy"
                                        width="100px"
                                        height="100%"
                                    />
                                </div>
                            </div>
                            <div className="content">
                                <h3>8. Website Maintenance</h3>
                                <p>We make sure the website is updated and backed up regularly.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BalanceTime;